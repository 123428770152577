import styled, { css } from "styled-components/macro";

import { isTouchscreenDevice } from "../../lib/isTouchscreenDevice";

export default styled.div`
    height: 100%;
    display: flex;
    user-select: none;
    flex-direction: row;
    align-items: stretch;

    > div {
        background: var(--primary-background);
        border: 1px solid var(--border-color);

        &:first-child {
            width: 56px;
            border-radius: 0.75rem;
        }
    }
`;

export const LeftSidebarWrapper = styled.div<{
    isDiscover?: boolean;
}>`
    height: 100%;
    display: flex;
    flex: ${(props) => (props.isDiscover ? "1 1 0" : "0")};
    flex-grow: 0;

    user-select: none;
    flex-direction: row;
    align-items: stretch;

    [data-known-size] {
        // a > div {
        //     padding: 0;
        // }
        // div {
        //     width: 48px;
        //     padding-right: 0;
        //     padding-left: 0;
        // }
    }

    > div {
        background: var(--primary-background);
        border: 1px solid var(--border-color);

        &:first-child {
            width: 68px;
            border-radius: 0.75rem;
            padding: 0 5px 0 5px;

            & > div:first-child {
                height: calc(100% - 60px) !important;
            }

            a {
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
                > div {
                    width: 48px;
                    height: 48px;
                    padding: 0 0 6px 0;

                    svg {
                        width: 48px;
                        height: 48px;
                    }
                }
            }

            a > svg {
                width: 48px;
                height: 48px;
            }

            [data-rbd-drag-handle-context-id] {
                padding: 0 0 6px 0;
                display: flex;
                justify-content: center;
            }

            & > div:nth-child(3) {
                width: 60px;
                height: 48px;
                margin-bottom: 10px;
                margin-left: -1px;
                border: 1px solid var(--light-border-color);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    margin-bottom: 0;
                }

                a > div {
                    width: 24px;
                    height: 24px;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
`;

export const RightSidebarWrapper = styled.div<{
    isMobile?: boolean;
}>`
    height: 100%;
    display: flex;
    width: ${(props) => (props.isMobile ? "none" : "310px")};
    user-select: none;
    flex-direction: row;
    align-items: stretch;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    margin-left: 8px;

    > div {
        width: 100%;
        background: var(--primary-background);
        border: none;
        padding: 0;
        border-radius: 16px;

        &:first-child {
            // width: 56px;
            border-radius: 0.75rem;
        }
    }
`;

const SideBar = ({
    className = "",
    customClass = "",
    children,
}: {
    customClass?: string;
    className?: string;
    children: any;
}) => <div className={`${className} ${customClass}`}>{children}</div>;

export const GenericSidebarBase = styled(SideBar)<{
    mobilePadding?: boolean;
    isRightSidebar?: boolean;
}>`
    height: 100%;
    width: ${(props) => (props.isRightSidebar ? "232px !important" : "312px")};
    display: flex;
    // flex-shrink: 0;
    flex-direction: column;
    /*border-end-start-radius: 8px;*/
    background: var(--primary-background);
    margin-left: 7px;
    border-radius: 1.25rem;

    border: ${(props) =>
        props.isRightSidebar ? "none" : "1px solid var(--border-color)"};

    /*> :nth-child(1) {
        //border-end-start-radius: 8px;
    }

    > :nth-child(2) {
        margin-top: 48px;
        background: red;
    }*/

    ${(props) =>
        props.mobilePadding &&
        isTouchscreenDevice &&
        css`
            padding-bottom: 50px;
        `}
`;

export const GenericSidebarList = styled.div`
    padding: 6px 0 6px 6px;
    flex-grow: 1;
    overflow-y: scroll;

    > img {
        width: 100%;
    }
`;

export const GenericSidebarMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 6px 5px 6px 6px;
    margin-bottom: 16px;
    border-left: 1px solid var(--border-color);

    svg {
        width: 20px;
        height: 16px;
    }
`;

export const Spacer = styled.div`
    width: 100%;
    height: 1px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--border-color);
`;
