import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { Text } from "preact-i18n";

import { ModalForm, Checkbox } from "@revoltchat/ui";

import { useClient } from "../../client/ClientController";
import { mapError } from "../../client/jsx/error";
import { ModalProps } from "../types";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 1rem;
`;

type Props = {
    isPublic?: boolean;
};

const Field = styled.div<Props>`
    margin-top: 1rem;
    opacity: ${(props) => (props.isPublic ? 0.5 : 1)};
`;

/**
 * Server creation modal
 */
export default function CreateServer({
    ...props
}: ModalProps<"create_server">) {
    const history = useHistory();
    const client = useClient();
    const [isPublic, setisPublic] = useState(false);

    return (
        <ModalForm
            {...props}
            title={<Text id="app.main.servers.create" />}
            description={
                <div>
                    By creating this server, you agree to the{" "}
                    <a
                        href="https://app.brc.4irehub.uk/terms"
                        target="_blank"
                        rel="noreferrer">
                        Acceptable Use Policy.
                    </a>
                    <CheckboxContainer>
                        <Checkbox
                            value={isPublic}
                            onChange={() => setisPublic(!isPublic)}
                            style={{
                                position: "relative",
                                width: "215px",
                                height: "30px",
                                padding: 0,
                            }}
                            title="Create the Public Server"
                        />
                    </CheckboxContainer>
                </div>
            }
            schema={{
                name: "text",
                ticker: "text",
                amount: "text",
                tagsStr: "text",
            }}
            data={{
                name: {
                    field: (
                        <Text id="app.main.servers.name" />
                    ) as React.ReactChild,
                },
                ticker: {
                    field: (
                        <Field isPublic={isPublic}>Token tiсker</Field>
                    ) as React.ReactChild,
                    disabled: isPublic,
                },
                amount: {
                    field: (
                        <Field isPublic={isPublic}>
                            Minimum amount of the token
                        </Field>
                    ) as React.ReactChild,
                    disabled: isPublic,
                },
                tagsStr: {
                    field: (<div>Tags</div>) as React.ReactChild,
                },
            }}
            callback={async ({ name, ticker, amount, tagsStr }) => {
                const tags = tagsStr.split(" ");

                const server = await client.servers
                    .createServer({
                        name,
                        ticker,
                        amount,
                        tags,
                    } as any)
                    .catch(mapError);

                history.push(`/server/${server._id}`);
            }}
            submit={{
                children: <Text id="app.special.modals.actions.create" />,
            }}
        />
    );
}
