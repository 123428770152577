// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { parseError } from "../../../lib/helpers";

export function takeError(error: any): string {
    if (error.response) {
        const type = error.response.data?.type;
        if (type) {
            return parseError(type);
        }

        switch (error.response.status) {
            case 429:
                return "Too Many Requests";
            case 401:
                return "Unauthorized";
            case 403:
                return "Forbidden";
            default:
                return "Unknown Error";
        }
    } else if (error.request) {
        return "Network Error";
    }

    return "Unknown Error";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapError(error: any): never {
    throw takeError(error);
}
